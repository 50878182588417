<template>
  <div class="crops">
    <PageTopNav
      :tabsShow="true"
      :addShow="true"
      :tabList="tabList"
      @addMeans="addMeans"
      @changeTab="changeTab"
    >
      <p slot="addName">添加作物</p>
    </PageTopNav>
    <div class="mains">
      <div class="l-search">
        <el-input
          class="search-input"
          :class="isFocusShow ? '' : 'active'"
          v-model.trim="title"
          autocomplete="off"
          placeholder="搜索你想要的作物"
          maxlength="20"
          @blur="blurInput"
        ></el-input>
        <div class="buttons" @click="getSearch">搜索</div>
      </div>
      <div class="lists" v-if="cropsList.length">
        <div
          class="items animated fadeInUp"
          v-for="(item, index) in cropsList"
          :key="item.id"
          data-wow-duration="1s"
          :data-wow-delay="index + 5 + 's'"
          data-wow-iteration="2"
        >
          <div class="col1">
            <img
              :src="
                item.photo
                  ? uploadImgs + item.photo
                  : require('../assets/image/crops/item1.jpg')
              "
              class="bgImgs"
            />
            <div class="tags">{{ item.title }}</div>
            <div class="icon-btn">
              <img
                class="edits"
                src="../assets/image/land/edits.png"
                @click="editMean(item.id)"
              />
              <img
                class="close"
                src="../assets/image/land/close.png"
                v-if="!item.custom_state"
                @click="enableCrops(item.id, index)"
              />
              <img
                class="open"
                src="../assets/image/land/open.png"
                v-if="item.custom_state"
                @click="disableCrops(item.id, index)"
              />
            </div>
          </div>
          <div class="col2">
            <div></div>
            <p class="one-txt-cut">
              批次数量：{{ item.batch_count ? item.batch_count : "0" }}
            </p>
          </div>
          <div class="col3 txt-cut">
            {{ item.desc }}
          </div>
          <div class="col4">
            <div class="buttons1" @click="setWarehousing(item.id)">
              添加批次
            </div>
            <div class="buttons2" @click="setWarehouse(item.id)">批次记录</div>
          </div>
        </div>
      </div>
      <div class="lists" v-else>
        <MyNothing></MyNothing>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
        v-if="cropsList.length"
      >
      </el-pagination>
    </div>
    <MyNav></MyNav>
    <!-- 添加批次 -->
    <el-dialog
      class="small-pop"
      title=" "
      :visible.sync="dialogVisible"
      width="662px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="pop-small">
        <div class="ware-title">
          <div class="left-line"></div>
          {{ PopTitle }}
        </div>
        <el-form
          class="forms"
          ref="batchForm"
          :model="batchForm"
          :rules="batchRules"
          hide-required-asterisk
        >
          <el-form-item
            label="批次名称"
            :label-width="formLabelWidth"
            prop="name"
          >
            <el-input
              v-model.trim="batchForm.name"
              autocomplete="off"
              placeholder="请输入批次名称"
              maxlength="20"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="种植地块"
            :label-width="formLabelWidth"
            prop="purposeVal"
          >
            <el-select v-model="batchForm.purposeVal" placeholder="选择地块">
              <el-option
                :label="item.title"
                :value="item.id"
                v-for="item in landList"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label=" " class="dialog-footer">
            <el-button
              class="ok-button"
              type="primary"
              @click="okWarehouse('batchForm')"
              >确 定</el-button
            >
            <el-button class="no-button" @click="noWarehouse('batchForm')"
              >取 消</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!-- 新增/编辑产品 -->
    <el-dialog
      class="big-pop"
      title=" "
      :visible.sync="addShow"
      width="666px"
      :before-close="setAddClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="big-main">
        <div class="ware-title">
          <div class="left-line"></div>
          {{ bigPopTitle }}
        </div>
        <div class="add-form">
          <el-form
            :model="form"
            ref="form"
            :rules="rules"
            hide-required-asterisk
          >
            <el-form-item
              label="作物封面"
              :label-width="formLabelWidth"
              prop="cover"
            >
              <el-upload
                :class="['uploadCover', imageUrl ? 'active' : '']"
                action="https://resource.darsing.net/api/v1/img"
                name="img"
                :limit="1"
                list-type="picture-card"
                :show-file-list="true"
                :file-list="fileList"
                :headers="uploadHeaders"
                :on-success="handleAvatarSuccess"
                :on-error="handleAvatarFail"
                :on-remove="removeFile"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
            <el-form-item
              label="作物名称"
              :label-width="formLabelWidth"
              prop="title"
            >
              <el-input
                v-model.trim="form.title"
                autocomplete="off"
                placeholder="请输入作物名称"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="作物分类"
              :label-width="formLabelWidth"
              prop="cid"
            >
              <el-select v-model="form.cid" placeholder="作物分类">
                <el-option
                  :label="item.name"
                  :value="item.id"
                  v-for="item in tabList"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="启用"
              :label-width="formLabelWidth"
              prop="custom_state"
            >
              <el-switch class="mySwitch" v-model="form.custom_state">
              </el-switch>
            </el-form-item>
            <el-form-item
              label="排序权重"
              :label-width="formLabelWidth"
              prop="weight"
            >
              <el-input
                v-model.number.trim="form.weight"
                autocomplete="off"
                placeholder="请输入排序权重"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="简介"
              :label-width="formLabelWidth"
              prop="desc"
            >
              <el-input
                v-model.trim="form.desc"
                type="textarea"
                autocomplete="off"
                placeholder="请输入作物简介"
                maxlength="200"
              >
              </el-input>
            </el-form-item>
            <el-form-item label=" " class="dialog-footer">
              <el-button class="ok-button" type="primary" @click="okAdd('form')"
                >确 定</el-button
              >
              <el-button class="no-button" @click="noAdd('form')"
                >取 消</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  showFullScreenLoading,
  tryHideFullScreenLoading,
} from "@/utils/screenLoading";
import {
  getProductClassListApi,
  getProductListApi,
  getPlotListApi,
  postAddBatchApi,
  getProductInfoApi,
  postEditProductInfoApi,
  postAddProductApi,
  postEditProductStateApi,
} from "@/request/api";
import { mapState } from "vuex";

export default {
  components: {
    PageTopNav: () => import("@/components/PageTopNav.vue"),
    MyNav: () => import("@/components/MyNav.vue"),
    MyNothing: () => import("@/components/MyNothing.vue"),
  },
  data() {
    return {
      total: 20,
      last_page: 1,
      per_page: 10,
      current_page: 1,
      fileList: [],
      imageUrl: "",
      uploadHeaders: {
        Authorization: localStorage.getItem("darsingToken"),
      },
      title: undefined,
      cid: undefined,
      tabList: [],
      cropsList: [],
      id: "",
      landList: [],
      dialogVisible: false,
      quantity: "",
      PopTitle: "新增批次",
      bigPopTitle: "",
      bigDialogShow: false,
      addShow: false,
      form: {
        title: "",
        weight: "",
        cid: "",
        desc: "",
        custom_state: 0,
        cover: "",
      },
      batchForm: {
        name: "",
        purposeVal: "",
      },
      batchRules: {
        name: [{ required: true, message: "请输入批次名称", trigger: "blur" }],
        purposeVal: [
          { required: true, message: "请选择地块", trigger: "change" },
        ],
      },
      rules: {
        title: [{ required: true, message: "请输入作物名称", trigger: "blur" }],
        desc: [{ required: true, message: "请输入简介", trigger: "blur" }],
        weight: [
          { required: true, message: "请输入排序权重", trigger: "blur" },
          {
            type: "number",
            message: "请填写数字",
            trigger: ["change", "blur"],
          },
        ],
        cname: [{ required: true, message: "请选择种类", trigger: "change" }],
      },
      formLabelWidth: "120px",
      isFocusShow: true,
    };
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      uploadImgs: (state) => state.app.uploadImgs,
    }),
  },
  watch: {
    addShow(newValue) {
      if (!newValue) {
        this.form = {
          title: "",
          weight: "",
          cid: "",
          desc: "",
          custom_state: 0,
          cover: "",
        };
        this.imageUrl = "";
        this.fileList = [];
      }
    },
  },
  created() {
    this.getProductClassListApi();
  },
  methods: {
    blurInput() {
      this.isFocusShow = this.title ? true : false;
    },
    removeFile() {
      this.imageUrl = "";
      this.form.photo = "";
    },
    handleAvatarFail() {
      this.$notify.error({
        title: "错误",
        message: "上传失败，稍后尝试",
      });
    },
    handleAvatarSuccess(res, file) {
      if (file.percentage === 100) {
        this.imageUrl = file.response.Data.imgid;
        this.$notify({
          title: "成功",
          message: "上传成功",
          type: "success",
        });
      }
    },
    handleCurrentChange(val) {
      this.current_page = val;
      this.getProductListApi();
    },
    async getProductListApi() {
      showFullScreenLoading();
      const { code, results } = await getProductListApi({
        manor_id: this.farmID,
        cid: this.cid,
        title: this.title,
        pn: this.current_page,
      });
      if (code === 0) {
        this.total = results.count;
        this.per_page = results.ps;
        this.current_page = results.pn;
        this.cropsList = results.data;
        // new this.$wow.WOW().init();
      }
      tryHideFullScreenLoading();
    },
    // 获取作物分类列表
    async getProductClassListApi() {
      const { code, results } = await getProductClassListApi({
        custom_state: 1,
        manor_id: this.farmID,
      });
      if (code === 0) {
        this.tabList = results.data;
        this.cid = this.$route.query.id || this.tabList[0].id;
        this.getProductListApi();
      }
    },
    enableCrops(id, index) {
      this.id = id;
      this.postEditProductStateApi(index, 1);
      this.$notify({
        title: "成功",
        message: "已启用",
        type: "success",
      });
    },
    async postEditProductStateApi(index, status) {
      const { code } = await postEditProductStateApi({
        product_id: this.id,
        custom_state: status,
      });
      if (code === 0) {
        this.cropsList[index].custom_state =
          !this.cropsList[index].custom_state;
        this.$forceUpdate();
      }
    },
    disableCrops(id, index) {
      this.id = id;
      this.$confirm("此操作将禁用该产品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.postEditProductStateApi(index, 0);
          this.$notify({
            title: "成功",
            message: "已禁用",
            type: "success",
          });
        })
        .catch(() => {
          this.$notify({
            title: "提示",
            message: "已取消操作",
          });
        });
    },
    changeTab(cid) {
      this.current_page = 1;
      this.cid = cid.id;
      this.title = undefined;
      this.getProductListApi();
    },
    getSearch() {
      if (!this.title) {
        this.isFocusShow = false;
        this.$notify.error({
          title: "错误",
          message: "请填写内容",
        });
      } else {
        this.getProductListApi();
      }
    },
    setAddClose() {
      this.addShow = false;
    },
    noAdd(formName) {
      this.addShow = false;
      this.$refs[formName].resetFields();
    },
    noWarehouse(formName) {
      this.dialogVisible = false;
      this.$refs[formName].resetFields();
    },
    // 新增批次
    async postAddBatchApi() {
      const res = await postAddBatchApi({
        manor_id: this.farmID,
        title: this.batchForm.name,
        product_id: this.id,
        plot_id: this.batchForm.purposeVal,
      });
      if (res.code === 0) {
        this.$notify({
          title: "成功",
          message: "已添加",
          type: "success",
        });
        this.$refs["batchForm"].resetFields();
        this.dialogVisible = false;
      }
    },
    okWarehouse(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.postAddBatchApi();
        } else {
          return false;
        }
      });
    },
    // 添加产品

    async postAddProductApi() {
      this.form.custom_state
        ? (this.form.custom_state = 1)
        : (this.form.custom_state = 0);
      const { code } = await postAddProductApi({
        manor_id: this.farmID,
        photo: this.imageUrl,
        ...this.form,
      });
      if (code === 0) {
        this.getProductListApi();
        this.$notify({
          title: "成功",
          message: "已添加",
          type: "success",
        });
      }
    },
    // 修改产品信息
    async postEditProductInfoApi() {
      this.form.custom_state
        ? (this.form.custom_state = 1)
        : (this.form.custom_state = 0);
      const { code } = await postEditProductInfoApi({
        manor_id: this.farmID,
        product_id: this.id,
        ...this.form,
        photo: this.imageUrl,
      });
      if (code === 0) {
        this.getProductListApi();
        this.$notify({
          title: "成功",
          message: "已修改",
          type: "success",
        });
      }
    },
    okAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.bigPopTitle === "新增作物") {
            this.postAddProductApi();
          } else {
            this.postEditProductInfoApi();
          }
          this.addShow = false;
        } else {
          return false;
        }
      });
    },
    async getProductInfoApi() {
      const { code, results } = await getProductInfoApi({
        product_id: this.id,
      });
      if (code === 0) {
        this.form = results;
        this.imageUrl = results.photo;
        if (results.photo && this.fileList.length === 0) {
          this.fileList.push({
            url: this.uploadImgs + results.photo,
          });
        }
        this.form.custom_state
          ? (this.form.custom_state = true)
          : (this.form.custom_state = false);
      }
    },
    // 点击编辑按钮
    editMean(id) {
      this.id = id;
      this.getProductInfoApi();
      this.bigPopTitle = "编辑作物";
      this.addShow = true;
    },
    addMeans() {
      this.bigPopTitle = "新增作物";
      this.addShow = true;
    },
    //  批次记录
    setWarehouse(id) {
      this.$router.push({
        name: "lot",
        query: { id },
      });
      sessionStorage.setItem("navLeft", "340px");
      sessionStorage.setItem("navIndex", 1);
    },
    // 获取地块列表
    async getPlotList() {
      const { code, results } = await getPlotListApi({
        manor_id: this.farmID,
      });
      if (code === 0) {
        this.landList = results.data;
      }
    },
    //   添加批次
    setWarehousing(id) {
      this.id = id;
      this.getPlotList();
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
      this.batchForm = {
        name: "",
        purposeVal: "",
      };
    },
  },
};
</script>

<style lang="less" scoped>
.crops {
  width: 100%;
  min-height: 100vh;
  background-color: #071a2c;
  padding-top: 62px;

  .mains {
    width: calc(100% - 96px);
    display: flex;
    flex-direction: column;
    margin: 12px auto 0;
    background-color: #0e2138;
    padding: 22px 49px;

    .l-search {
      display: flex;
      align-items: center;
      margin-bottom: 27px;

      /deep/.el-input {
        width: 378px;
        margin-right: 52px;
        .el-input__inner {
          width: 100%;
          background-color: #081c30;
          border: 1px solid #385576;
          color: #57708d;
          font-size: 14px;
        }
        &.active {
          .el-input__inner {
            border-color: #f56c6c;
          }
        }
      }

      .buttons {
        margin-left: 20px;
        width: 90px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #3e90e5;
        border-radius: 3px;
        color: #fff;
        font-size: 14px;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .lists {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .items {
        margin: 0 40px 34px 0;
        width: 307px;
        height: 328px;
        background: #081c30;
        border: 1px solid #385576;
        padding: 13px 15px 0;
        display: flex;
        flex-direction: column;

        .col1 {
          width: 100%;
          height: 184px;
          margin-bottom: 14px;
          position: relative;

          .bgImgs {
            width: 100%;
            height: 100%;
          }

          .tags {
            min-width: 108px;
            text-align: center;
            height: 30px;
            line-height: 30px;
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
            position: absolute;
            left: 0;
            top: 12px;
            color: #fff;
            font-weight: Bold;
            font-size: 16px;
            background-color: #3e90e5;
          }

          .icon-btn {
            display: flex;
            align-items: center;
            display: none;
            position: absolute;
            right: 13px;
            top: 12px;

            img {
              width: 33px;
              height: 32px;
              cursor: pointer;
              margin-right: 9px;

              :last-of-type {
                margin-right: 0;
              }
            }
          }
        }

        .col2 {
          width: 100%;
          border-radius: 14px;
          display: flex;
          align-items: center;
          margin-bottom: 14px;

          > div {
            width: 5px;
            height: 5px;
            background: #3e90e5;
            border-radius: 50%;
            margin-right: 11px;
          }

          p {
            width: calc(100% - 5px);
            color: #fff;
            font-size: 14px;
            margin: 0;
          }
        }

        .col3 {
          width: 100%;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #a9b8c8;
          line-height: 16px;
          margin-bottom: 14px;
        }

        .col4 {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .buttons1,
          .buttons2,
          .buttons3 {
            width: 120px;
            height: 28px;
            border-radius: 3px;
            color: #fff;
            font-size: 14px;
            line-height: 28px;
            text-align: center;
            cursor: pointer;

            &:hover {
              opacity: 0.8;
            }
          }

          .buttons1 {
            background: #67c23a;
          }

          .buttons2 {
            background: #3e90e5;
          }

          .buttons3 {
            background: #67c23a;
          }
        }

        &:nth-of-type(5n) {
          margin-right: 0;
        }

        :hover {
          .icon-btn {
            display: block;
          }
        }
      }
    }
  }

  .small-pop,
  .big-pop {
    /deep/.el-dialog {
      width: 666px !important;
      background-color: inherit;
    }

    /deep/.el-dialog__body {
      padding: 0;
    }

    /deep/.el-dialog__headerbtn {
      font-size: 26px;
      top: 64px;
      right: 50px;
      font-weight: bold;
    }
  }

  .pop-small {
    width: 662px;
    height: 415px;
    background: url(../assets/image/land/popBgS.png) no-repeat center center;
    background-size: cover;
    padding: 45px 55px 0 55px;

    .ware-title {
      font-size: 22px;
      color: #fff;
      font-weight: bold;
      display: flex;
      margin-bottom: 55px;

      .left-line {
        background-color: #fff;
        width: 4px;
        height: 21px;
        border-radius: 3px;
        margin-right: 10px;
      }
    }
    /deep/.el-input__inner {
      background-color: #081c30;
      border: 1px solid #385576;
      color: #57708d;
    }
    /deep/.el-form-item__label {
      color: #fff;
    }
    /deep/.el-button span {
      color: #fff;
    }

    /deep/.dialog-footer {
      padding: 0 0 0 91px;
    }

    .ok-button {
      width: 150px;
      height: 40px;
      margin-right: 44px;
    }

    .no-button {
      width: 150px;
      height: 40px;
      border: 1px solid #385576;
      background-color: #0f243d;
    }
  }

  .big-pop {
    /deep/.el-form-item {
      margin-bottom: 32px;
    }

    /deep/.el-button span {
      color: #fff;
    }

    /deep/.el-dialog__headerbtn {
      top: 71px;
      right: 44px;
    }

    /deep/.dialog-footer {
      margin-top: 40px;
      padding: 0 0 0 119px;
    }

    .ok-button {
      width: 150px;
      height: 40px;
      margin-right: 44px;
    }

    .no-button {
      width: 150px;
      height: 40px;
      border: 1px solid #385576;
      background-color: #0f243d;
    }

    .big-main {
      width: 666px;
      height: 693px;
      background: url(../assets/image/land/popBgB.png) no-repeat center center;
      background-size: cover;
      padding: 45px 55px;
      overflow: hidden;
      .ware-title {
        font-size: 22px;
        color: #fff;
        font-weight: bold;
        display: flex;
        margin-bottom: 40px;

        .left-line {
          background-color: #fff;
          width: 4px;
          height: 21px;
          border-radius: 3px;
          margin-right: 10px;
        }
      }

      .block {
        width: 100%;
        height: calc(100% - 124px);
        overflow: auto;
        padding: 0 2px;

        &::-webkit-scrollbar {
          width: 1px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #ccc;
        }
      }
      /deep/.uploadCover {
        width: 158px;
        height: 105px;
        display: flex;
        .el-upload--picture-card {
          width: 100%;
          height: 100%;
          line-height: 105px;
        }
        .el-upload-list--picture-card .el-upload-list__item {
          width: 158px;
          height: 105px;
        }
        &.active {
          .el-upload--picture-card {
            display: none;
          }
        }
      }
      /deep/.el-card {
        background-color: #081c30;
        border: none;
      }

      /deep/.el-timeline-item__tail {
        border-left: 2px solid #385576;
      }

      /deep/.el-timeline-item__timestamp {
        font-size: 18px;
        color: #fff;
      }

      /deep/.el-card__body {
        width: 450px;
        min-height: 83px;
        padding: 18px 15px;
      }

      /deep/.el-timeline-item__node {
        background-color: #67c23a;
      }

      /deep/.el-timeline-item.active {
        .el-timeline-item__node {
          background-color: #e60000;
        }
      }

      .info-col1 {
        margin-bottom: 16px;

        span {
          font-size: 18px;
          color: #fff;
          margin-right: 16px;
        }

        .num {
          color: #e60000;
        }

        .num.active {
          color: #67c23a;
        }
      }

      .info-col2 {
        span {
          font-size: 14px;
        }

        .text1 {
          color: #57708d;
          margin-right: 14px;
        }

        .text2 {
          color: #fff;
          margin-right: 20px;
        }

        .text3 {
          color: #57708d;
          margin-right: 10px;
        }

        .text4 {
          color: #3e90e5;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      /deep/.el-input__inner {
        background-color: #081c30;
        border: 1px solid #385576;
        color: #57708d;
        width: 353px;
      }

      /deep/.el-textarea__inner {
        background-color: #081c30;
        border: 1px solid #385576;
        color: #57708d;
        width: 353px;
        height: 120px;
        resize: none;
      }

      /deep/.el-form-item__label {
        font-size: 14px;
        color: #fff;
        padding: 0 24px 0 0;
      }

      /deep/.el-radio__input.is-checked + .el-radio__label {
        color: #fff;
      }

      /deep/.el-radio {
        color: #fff;
      }
      .add-form {
        width: 100%;
        height: calc(100% - 56px);
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #3e90e5;
        }
      }
    }
  }
  .small-pop {
    /deep/.el-dialog {
      width: 662px !important;
    }
  }
}
</style>
